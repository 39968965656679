var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"order-list-table-container"},[_c('div',{staticClass:"description"},[_c('span',[_vm._v(_vm._s(_vm.$t("ORDER_LIST_TABLE_COMPONENT_TITLE_DESCRIPTION")))])]),_c('CCard',{staticClass:"order-list-table-card"},[_c('CDataTable',{attrs:{"items":_vm.items,"fields":_vm.fields,"fixed":"","hover":"","striped":"","bordered":""},scopedSlots:_vm._u([{key:"#",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',[_vm._v(_vm._s(index + 1 + (_vm.activePage-1)*_vm.limit))])]}},{key:"created_at",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm._f("moment")((item.created_at),_vm.dateFormat)))])]}},{key:"first_name",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.member.first_name || '-'))])]}},{key:"last_name",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.member.last_name || '-'))])]}},{key:"email",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.member.email))])]}},{key:"membership_type",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('StakingBadge',{staticClass:"no-border",attrs:{"type":"membership-type","text":item.membership_type.name}})],1)]}},{key:"payment_type",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.payment_type === 'Bank')?_c('span',[_vm._v(_vm._s(_vm.$t("ORDER_LIST_TABLE_COMPONENT_TEXT_PAYMENT_TYPE_BANK")))]):_c('span',[(!item.wallet_id)?_c('span',[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.getMappingCurrencySymbol(item.currency_symbol))+" ")])])]}},{key:"payment_info",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.wallet_address || ''))])]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-left"},[_c('StakingBadge',{staticClass:"no-border",attrs:{"type":"membership-order-status","text":item.status}})],1)]}},{key:"action",fn:function(ref){
var item = ref.item;
return [_c('td',[(_vm.isAllowedViewDetails)?_c('button',{staticClass:"btn btn-edit",on:{"click":function($event){return _vm.edit(item)}}},[_c('img',{staticClass:"hand edit-icon",attrs:{"src":require("@/assets/images/ico-edit-user.svg"),"alt":""}})]):_vm._e()])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }