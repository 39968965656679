<template>
  <div class="order-list-table-container">
    <div class="description">
      <span>{{$t("ORDER_LIST_TABLE_COMPONENT_TITLE_DESCRIPTION")}}</span>
    </div>
    <CCard class="order-list-table-card">
      <CDataTable :items="items" :fields="fields" fixed hover striped bordered>
        <template ##="{item, index}">
          <td>{{index + 1 + (activePage-1)*limit}}</td>
        </template>

        <template #created_at="{item}">
          <td>{{(item.created_at) | moment(dateFormat)}}</td>
        </template>

        <template #first_name="{item}">
          <td>{{item.member.first_name || '-'}}</td>
        </template>

        <template #last_name="{item}">
          <td>{{item.member.last_name || '-'}}</td>
        </template>

        <template #email="{item}">
          <td>{{item.member.email}}</td>
        </template>

        <template #membership_type="{item}">
          <td>
            <StakingBadge
              class="no-border"
              type="membership-type"
              :text="item.membership_type.name"
            />
          </td>
        </template>

        <template #payment_type="{item}">
          <td>
            <span
              v-if="item.payment_type === 'Bank'"
            >{{$t("ORDER_LIST_TABLE_COMPONENT_TEXT_PAYMENT_TYPE_BANK")}}</span>
            <span v-else>
              <span v-if="!item.wallet_id">*</span>
              {{getMappingCurrencySymbol(item.currency_symbol) }}
            </span>
          </td>
        </template>

        <template #payment_info="{item}">
          <td>{{item.wallet_address || '' }}</td>
        </template>

        <template #status="{item}">
          <td class="text-left">
            <StakingBadge class="no-border" type="membership-order-status" :text="item.status" />
          </td>
        </template>

        <template #action="{item}">
          <td>
            <button class="btn btn-edit" v-if="isAllowedViewDetails" @click="edit(item)">
              <img src="@/assets/images/ico-edit-user.svg" alt class="hand edit-icon" />
            </button>
          </td>
        </template>
      </CDataTable>
    </CCard>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import StakingBadge from '@/components/StakingBadge';
import { Constants } from '@/constants';
import currencyHelper from '@/utils/currency-helper';

export default {
  name: 'OrderListTable',
  components: {
    StakingBadge,
  },
  data() {
    return {
      dateFormat: Constants.dateFormat,
      getMappingCurrencySymbol: currencyHelper.getMappingCurrencySymbol,
      fields: [
        {
          key: 'order_no',
          label: this.$t('ORDER_LIST_TABLE_COMPONENT_LABEL_ORDER'),
        },
        {
          key: 'created_at',
          label: this.$t('ORDER_LIST_TABLE_COMPONENT_LABEL_DATE_TIME'),
          _style: 'min-width: 150px; text-align: left;',
        },
        {
          key: 'first_name',
          label: this.$t('ORDER_LIST_TABLE_COMPONENT_LABEL_FIRST_NAME'),
        },
        {
          key: 'last_name',
          label: this.$t('ORDER_LIST_TABLE_COMPONENT_LABEL_LAST_NAME'),
        },
        {
          key: 'email',
          label: this.$t('ORDER_LIST_TABLE_COMPONENT_LABEL_EMAIL'),
        },
        {
          key: 'membership_type',
          label: this.$t('ORDER_LIST_TABLE_COMPONENT_LABEL_MEMBERSHIP_TYPE'),
        },
        {
          key: 'payment_type',
          label: this.$t('ORDER_LIST_TABLE_COMPONENT_LABEL_PAYMENT_TYPE'),
        },
        {
          key: 'payment_info',
          label: this.$t('ORDER_LIST_TABLE_COMPONENT_LABEL_PAYMENT_INFO'),
        },
        {
          key: 'status',
          label: this.$t('ORDER_LIST_TABLE_COMPONENT_LABEL_STATUS'),
          _style: 'min-width: 80px; text-align: left;',
        },
        {
          key: 'action',
          label: '',
        },
      ],
    };
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    activePage: {
      type: Number,
      default: 0,
    },
    limit: {
      type: Number,
      default: 0,
    },
    isAllowedViewDetails: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    edit(item) {
      this.$router.push(`membership-orders/${item.id}/details`);
    },
  },
};
</script>

<style lang="scss">
.order-list-table-container {
  > .description {
    height: 16px;
    color: #8a93a2;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
  }

  .order-list-table-card {
    .table {
      margin-bottom: 0;
      .hand {
        cursor: pointer;
      }
      .btn {
        font-size: 14px;
        font-weight: 400;
        color: #0505a5;
        outline: none;
        padding-top: 0;
        padding-bottom: 0;

        &.btn-edit {
          outline: none;
        }
      }
    }
  }
}
</style>
