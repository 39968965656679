<template>
  <div class="order-list-container">
    <CCard class="staking-card">
      <CCardHeader>
        <CRow>
          <CCol md="3" sm="12">
            <h1>{{$t("ORDER_LIST_PAGE_TITLE_MEMBER_ORDERS")}}</h1>
          </CCol>
          <CCol md="6" sm="12"></CCol>
          <CCol md="3" sm="12">
            <CButton
              class="btn btn-go-to-exchange-rate-page"
              @click="goToExchangeRatePage()"
              v-if="isAllowedUpdateExchangeRate"
            >{{$t("ORDER_LIST_PAGE_BUTTON_UPDATE_EXCHANGE_RATE")}}</CButton>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <SearchOrdersHeader
          :onSearch="onSearch"
          :options="options"
          :downloadCSV="downloadCSV"
          :isAllowedDownloadCSV="isAllowedDownloadCSV"
        />
        <OrderListTable
          :limit="limit"
          :activePage="activePage"
          :items="items"
          :total="total"
          :isAllowedViewDetails="isAllowedViewDetails"
        />

        <StakingPagination
          :limit="limit"
          :total="total"
          :activePage="activePage"
          @changePage="onChangePage"
          @changePageSize="onChangePageSize"
        />
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import _ from 'lodash';
import moment from 'moment';
import { saveAs } from 'file-saver';
import OrderListTable from '@/components/MembershipOrder/OrderListTable';
import SearchOrdersHeader from '@/components/MembershipOrder/SearchOrdersHeader';
import StakingPagination from '@/components/StakingPagination';
import { endpoints, env } from '@/constants';
import { mapState, mapActions, mapGetters } from 'vuex';
import responseHelper from '@/utils/response-helper';
import { MembershipOrderStatus, PaymentType } from '@/enums';
import sessionStorageHelper from '@/utils/sessionStorage-helper';
import dateTimeHelper from '@/utils/datetime-helper';

export default {
  name: 'OrderList',
  components: {
    OrderListTable,
    SearchOrdersHeader,
    StakingPagination,
  },
  data() {
    return {
      items: [],
      options: [],
      filterData: {},
      total: 0,
      limit: env.app.pageSize,
      activePage: 1,
      isAllowedViewDetails: false,
      isAllowedApprove: false,
      isAllowedReject: false,
      isAllowedDownloadCSV: false,
      isAllowedUpdateExchangeRate: false,
    };
  },
  computed: {
    ...mapGetters(['membershipTypes', 'userPermissions']),
    offset() {
      return (this.activePage - 1) * this.limit;
    },
    pages() {
      return Math.ceil(this.total / this.limit);
    },
  },
  async mounted() {
    this.checkPermissions();

    let paidMemberships = this.membershipTypes.filter(x => !!x.is_enabled);
    paidMemberships = _.sortBy(paidMemberships, item => {
      return item.display_order || 0;
    });

    this.options = paidMemberships;
  },
  methods: {
    checkPermissions() {
      if (this.userPermissions.length > 0) {
        this.userPermissions.forEach(item => {
          if (item.name === 'MEMBERSHIP_VIEW_ORDER_DETAIL') {
            this.isAllowedViewDetails = true;
          }

          if (item.name === 'MEMBERSHIP_CONFIRM_ORDER') {
            this.isAllowedApprove = true;
          }

          if (item.name === 'MEMBERSHIP_REJECT_ORDER') {
            this.isAllowedReject = true;
          }

          if (item.name === 'MEMBERSHIP_EXPORT_CSV_ORDERS') {
            this.isAllowedDownloadCSV = true;
          }

          if (item.name === 'MEMBERSHIP_UPDATE_FIAT_RATE') {
            this.isAllowedUpdateExchangeRate = true;
          }
        });
      }
    },
    goToExchangeRatePage() {
      this.$router.push('/admin/exchange-rates');
    },
    async getOrderList(data) {
      data = data || {};

      this.filterData = {
        ...this.filterData,
        ...data,
      };
      const filterData = this.filterData;

      try {
        const params = {
          ...this.getPaymentParams(filterData.paymentType),
          limit: this.limit,
          offset: this.offset,
          from: filterData.fromDate,
          to: dateTimeHelper.adjustToDate(filterData.toDate),
          order_no: filterData.orderNumber,
          email: filterData.email,
          crypto_receive_address: filterData.receiveAddress,
          payment_status: filterData.paymentStatus,
          last_name: filterData.lastName,
          first_name: filterData.firstName,
        };
        const result = await this.$http.get(endpoints.getMembershipOrders, { params });

        this.items = result.data.items || [];
        this.items.forEach(item => (item.status = MembershipOrderStatus[item.status]));
        this.total = result.data.total;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('ORDER_LIST_PAGE_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    getPaymentParams(paymentType) {
      const params = {};

      switch (paymentType) {
        case PaymentType.Bank:
          params.is_bank = 1;
          break;

        case PaymentType.BTC:
          params.is_crypto = 1;
          params.currency_symbol = 'BTC';
          break;

        case PaymentType.ETH:
          params.is_crypto = 1;
          params.currency_symbol = 'ETH';
          break;

        case PaymentType.USDT:
          params.is_crypto = 1;
          params.currency_symbol = 'USDT';
          break;

        case PaymentType.ExternalBTC:
          params.is_crypto = 1;
          params.is_external = 1;
          params.currency_symbol = 'BTC';
          break;

        case PaymentType.ExternalUSDT:
          params.is_crypto = 1;
          params.is_external = 1;
          params.currency_symbol = 'USDT';
          break;

        case PaymentType.ExternalETH:
          params.is_crypto = 1;
          params.is_external = 1;
          params.currency_symbol = 'ETH';
          break;
      }

      return params;
    },
    async onChangePage(page) {
      this.activePage = page;

      this.getOrderList();
    },
    async onChangePageSize(value) {
      this.limit = value;
      this.activePage = 1;

      this.getOrderList();
    },
    async onSearch(searchData) {
      const data = searchData || sessionStorageHelper.getData('filter-orders');
      this.activePage = 1;

      this.getOrderList(data);
    },
    async downloadCSV(data) {
      this.filterData = {
        ...this.filterData,
        ...data,
      };
      const filterData = this.filterData;

      try {
        const params = {
          ...this.getPaymentParams(filterData.paymentType),
          limit: this.limit,
          offset: this.offset,
          from: filterData.fromDate,
          to: filterData.toDate,
          order_no: filterData.orderNumber,
          email: filterData.email,
          crypto_receive_address: filterData.receiveAddress,
          payment_status: filterData.paymentStatus,
          last_name: filterData.lastName,
          first_name: filterData.firstName,
          time_offset: new Date().getTimezoneOffset(),
        };
        const result = await this.$http.get(endpoints.downloadCsvOrder, { params });
        const data = result;
        const fileUrl = 'membership-orders.csv';
        const contentType = 'application/octet-stream';
        const file = new Blob([data], { type: contentType });
        saveAs(file, fileUrl);
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('ORDER_LIST_PAGE_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
  },
};
</script>
<style lang="scss">
.order-list-container {
  padding: 0 24px;
  @media screen and (max-width: 1024px) {
    padding: 0 15px;
  }

  .card {
    > .card-header {
      border-bottom: none;
      padding-bottom: 0px;

      h1 {
        color: #657187;
        font-size: 18px;
        font-weight: 600;
        line-height: 34px;
        margin-bottom: 0px;
      }

      .btn-go-to-exchange-rate-page {
        border: none;
        border-radius: 32px;
        height: 32px;
        padding: 0 30px;
        color: #fff;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 14px;
        text-align: center;
        border: 1px solid #1a2958;
        border-radius: 17.5px;
        background-color: #1a2958;
        width: 100%;
        display: block;
        color: #5da30b;
        font-weight: 500;
        letter-spacing: 0.5px;
        line-height: 16px;
        text-align: center;
        border: 1px solid #5da30b;
        border-radius: 16px;
        background-color: #ffffff;
      }
    }
  }
}
</style>
